import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CallIcon from '@material-ui/icons/Call';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extendedIcon: {
      marginRight: theme.spacing(3),
    },
  }),
);

const BookCall = () => {
  const classes = useStyles();
  const bookPhoneCall = () => {
    window.open('https://calendly.com/gavin-swirl-creative/30min', '_blank');
  };
  const smaller = useMediaQuery('(max-width:663px)');
  return (
    <>
      {smaller && (
        <Fab
          variant="extended"
          color="primary"
          className="Footer-call-fab"
          href="https://calendly.com/gavin-swirl-creative/30min"
          target="_blank"
        >
          <CallIcon className={classes.extendedIcon} />
          Book a call
        </Fab>
      )}
      {!smaller && (
        <Fab
          variant="extended"
          color="primary"
          href="https://calendly.com/gavin-swirl-creative/30min"
          target="_blank"
        >
          <CallIcon className={classes.extendedIcon} />
          Book a call with us today
        </Fab>
      )}
    </>
  );
};

export default BookCall;
