/** @jsx jsx */
import React from 'react';
import { Footer as ThemeFooter, jsx } from 'theme-ui';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BookCall from './book-call';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '0 3px',
    },
    transform: {
      transform: `translate(0, -10px) !important`,
    },
  }),
);

const Footer = ({ isGeneric = false }) => {
  const classes = useStyles();
  if (isGeneric) {
    return (
      <ThemeFooter id="contact" className={classes.transform}>
        <div className="Footer-inner">
          <BookCall />
          <div id="socialicons">
            <a href="https://www.facebook.com/swirlwebdesign" className={classes.root}>
              <FacebookIcon color="primary" />
            </a>
            <a href="https://twitter.com/CreativeSwirl" className={classes.root}>
              <TwitterIcon color="primary" />
            </a>
            <a
              href="https://www.linkedin.com/company/swirl-creative-llc"
              className={classes.root}
            >
              <LinkedInIcon color="primary" />
            </a>
            <a href="https://www.instagram.com/swirl.creative/" className={classes.root}>
              <InstagramIcon color="primary" />
            </a>
          </div>
          <p>
            Copyright &copy; {new Date().getFullYear()} Swirl Creative LLC. All rights
            reserved.
          </p>
        </div>
      </ThemeFooter>
    );
  }
  return (
    <ThemeFooter id="contact" className>
      <div className="Footer-inner">
        <BookCall />
        <div id="socialicons">
          <a href="https://www.facebook.com/swirlwebdesign" className={classes.root}>
            <FacebookIcon color="primary" />
          </a>
          <a href="https://twitter.com/CreativeSwirl" className={classes.root}>
            <TwitterIcon color="primary" />
          </a>
          <a
            href="https://www.linkedin.com/company/swirl-creative-llc"
            className={classes.root}
          >
            <LinkedInIcon color="primary" />
          </a>
          <a href="https://www.instagram.com/swirl.creative/" className={classes.root}>
            <InstagramIcon color="primary" />
          </a>
        </div>
        <p>
          Copyright &copy; {new Date().getFullYear()} Swirl Creative LLC. All rights
          reserved. Review our{' '}
          <a className="faded" href="/privacy-policy">
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </ThemeFooter>
  );
};

export default Footer;
